import slugify from "slugify";
import { getCompleteSlug } from "@lib/utils/getCompleteSlug";
import { getPagePathByLocale, productDetailPage } from "@lib/constants/pagePaths";

export interface PDPSlugParts {
    sku: string;
    slug: string;
    colorCode: string;
    productName: string;
    articleNumber: string;
    distributionChannelWhereClause: string;
}

export const getPDPSlug = (
    productName: string | string[],
    articleNumber: string | string[],
    colorCode: string | string[],
    locale: string
): PDPSlugParts => {
    const distributionChannelId = process.env.NEXT_PUBLIC_DISTRIBUTION_CHANNEL_ID;

    if (!distributionChannelId) {
        throw Error("missing NEXT_PUBLIC_DISTRIBUTION_CHANNEL_ID");
    }

    const productNameString = productName?.toString() || "";
    const articleNumberString = articleNumber?.toString() || "";
    const colorCodeString = colorCode?.toString() || "";

    return {
        productName: productNameString,
        articleNumber: articleNumberString,
        colorCode: colorCodeString,
        sku: `${articleNumber}${colorCode}`,
        slug: getCompleteSlug(
            [slugify(productNameString?.toLowerCase() || ""), articleNumberString, colorCodeString],
            getPagePathByLocale(productDetailPage, locale)
        ),
        distributionChannelWhereClause: `productSelection(id=\"${distributionChannelId}\")`,
    };
};
